<template>
  <div class="error-box">
    <div class="py-12 p-35">
      <div class="text-center">
        <div class="mb-3 mt-5">
          <img src="@/assets/images/icon/error.svg" width="20%">
        </div>
        <h1> {{ $t('common.access_denied') }}</h1>
        <h3> {{ $t('common.contact_admin') }}</h3>
        <b-button
            variant="danger"
            pill
            size="lg"
            @click="Logout()"
            class="mt-3"
        > {{ $t('navbar.signout') }}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    Logout() {
      localStorage.removeItem('profile')
      localStorage.removeItem('token')
      sessionStorage.clear()
      this.$router.push('/Login')
    },
  }
}
</script>
<style scoped>
</style>